import React from 'react';
import { Link } from 'react-router-dom';
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

const LinkA = tw.a`underline text-secondary-100 hover:text-black`;

const translations = {
    pageInfo: {
        locationString: '/es/',
        title: 'Gunbot: Bot de Trading de siguiente nivel | Sitio oficial',
        description: 'Descubre Gunbot, el bot de trading autoalojado y fácil de usar. Trading sin esfuerzo en Binance, Bybit, Coinbase, dYdX y más. Para spot, futuros y DeFi. Elija entre planes de por vida o suscripción.',
    },
    hero: {
        heading: "Gunbot. Bot de trading creado por la comunidad",
        description: (
            <>
                Herramienta de automatización de trading para más de 20 exchanges. Funciona tanto en Windows como en Linux y macOS. Los secretos de la API se guardan en su propio dispositivo.
                <br /><br />
                Use estrategias preajustadas que simplemente funcionan, o realice sus propias ideas de automatización.
                Gunbot está disponible como licencia permanente o subscripción.
            </>
        ),
        notification: "Promoción Bitcoin #halving: Ranura extra gratuito para su exchange favorito en Licencias permanentes y upgrades. ⚡",
        comparePlansButton: "Compare los planes",
        newsAndBlogButton: "Noticias y Blog",
        defiTradingBotButton: "Bot de trading Defi",
        checkPerformanceButton: "Evalua el rendimiento",
        officialPartnersText: "Partners oficiales",
        partnersAltText: "Partners oficiales de Gunbot: Binance, Kucoin, Okex, HitBTC, Kraken Futures and more",
        recentNewsAndArticlesText: "Noticias y artículos recientes:",
        plansLink: '/es/planes/',
        defiLink: '/es/bot-de-trading-defi/',
        performanceLink: '/es/rendimiento/',
    },
    showcase: {
        step3: {
            step: "paso 3.",
            label: "Opere como un pro",
            heading: "Consiga operaciones increíbles",
            description: (
                <>
                    Gunbot viene con impresionantes estrategias preconfiguradas. <br />Esta gráfica muestra operaciones con stepGrid.
                </>
            ),
            alt1: "Captura exhibiendo operaciones exitosas de Gunbot.",
        },
        step2: {
            step: "paso 2.",
            label: "Seleccione un mercado",
            heading: "Busque mercados en tendencia",
            description: (
                <>
                    Compare todos los mercados de un solo vistazo. <br />Active un bot de trading en cuestión de segundos.
                </>
            ),
            alt2: "Imagen que ilustra la comparación entre diferentes mercados.",
        },
        step1: {
            step: "paso 1.",
            label: "Conecte el exchange",
            heading: "Conexión directa a través de API",
            description: (
                <>
                    No hay intermediarios entre su bot y el exchange. <br />Se configura en menos de 5 minutos.
                </>
            ),
            alt3: "Pantallazo explicativo de cómo añadir fácilmente tus credenciales API en Gunbot.",
        }
    },
    callToAction: {
        text: "Descubre cómo funciona realmente el trading con Gunbot.",
        primaryLinkText: "Todas las características de trading",
        primaryLinkUrl: "/es/caracteristicas/",
        faqLinkTitle: "Preguntas de uso frecuente",
        faqLinkUrl: "https://www.gunbot.com/support/faq/",
    },
    gunbotAI: {
        subheading: "Libere su potencial de trading al instante!",
        heading: "Cree estrategias personalizadas generadas por IA",
        description: `Imagine transformar sus conceptos de trading en código en cuestión de segundos. Con Gunbot AI, puede convertir sus pensamientos en realidad con una simple descripción. Esta potente herramienta te permite crear prototipos de nuevas estrategias de trading sin esfuerzo.
    
    Desarrollado por la avanzada tecnología detrás de ChatGPT, Gunbot AI está diseñado tanto para los comerciantes de criptomonedas como para los expertos en automatización. Entiende todos los métodos y fuentes de datos específicos de Gunbot, por lo que es la opción ideal tanto para desarrolladores como para aspirantes.
    
    Es esencial ser precavido cuando se utiliza por primera vez un código generado por una máquina. Ejecútalo siempre en una instancia simulada del bot para garantizar su fiabilidad.
    
    Explore las infinitas posibilidades del trading automatizado con Gunbot AI. Empiece hoy mismo a crear sus estrategias personalizadas y revolucione su experiencia de trading.  
    Los ejemplos no se ha editado el código.`,
        actionButtonText: "Ver más ejemplos de Gunbot AI",
        actionButtonURL: "https://www.gunbot.com/support/docs/custom-strategies/example-strategies/overview/",
    },
    how: {
        text: "Compruebe lo fácil que es empezar a utilizar el Gunbot. Solamente le llevará un par de minutos.",
        primaryLinkText: "Cómo funciona Gunbot",
        primaryLinkUrl: "/es/como-funciona-gunbot/",
        primaryLinkTitle: "Como instalar Gunbot"
    },
    pricingModule: {
        heading: 'Escoja su bot',
        subheading: '',
        description: "Seleccione la modalidad de pago:",
        yearlyAdmonition: '-50%',
        lifetimeAdmonition: 'Gran valor',
        primaryButtonText: "¡Lo quiero!",
        tabs: {
            'Mensual': [],
            'Anual': [],
            'De por vida': [],
        },
        plansDescription: <p className={"description"}>Dato curioso: <br /><br />Puede ahorrar el 50% con planes anuales 😉</p>,
        plansDescriptionAnnual: <p className={"description"}>Dato curioso: <br /><br />Consiga un plan permanente para ahorrar aún más 😉</p>,
        plansDescriptionLifetime: <p className={"description"}>Dato curioso: <br /><br />Permanente significa pago único, uselo de por vida 😎</p>,
        showYearlyPlans: "Mostrat Planes Anuales",
        showLifetimePlans: "Mostrar Planes De por vida",
        showMonthlyPlans: "Mostrar Planes Mensuales",
        joinGunbotCommunity: "Unete a la comunidad",
        moreDetailsAbout: "Más detalles sobre,",
        planFeatures: [
            "Feature 1 description",
            "Feature 2 description",
        ],
    },
    features: {
        heading: "El trading con bots bien hecho",
        cards: [
            {
                title: "Estrategias innovadoras",
                description: "Elija entre más de 20 estrategias de trading predefinidas con ajustes predeterminados probados. Utilizando más de 150 parámetros diferentes, puede adaptar cada estrategia a su estilo de trading."
            },
            {
                title: "Sin límite de pares de trading",
                description: "No hay límites a la cantidad de pares de trading que Gunbot puede operar activamente. Usted decide si quiere operar uno o cien mercados simultáneamente."
            },
            {
                title: "Additional trailing",
                description: "Utilice las opciones de trailing de precios configurables para obtener mejores operaciones. De esta forma, las operaciones sólo se realizan cuando los precios dejan de moverse en una dirección determinada."
            },
            {
                title: "Indicadores de confirmación",
                description: "Use indicadores estándar de la industria para restringir las operaciones a condiciones de mercado muy específicas. Elija entre ADX, RSI, StochRSI, MFI, EMA, EMAspread y muchos más."
            },
            {
                title: "AutoConfig",
                description: "No intente vencer a un mercado dinámico con una estrategia estática. Gunbot es el único bot de trading que ofrece cambios de configuración dinámicos basados en reglas. Utilice las reglas de filtro integradas o codifique las suyas propias."
            },
            {
                title: "Visual targets",
                description: "Haciendo uso de la biblioteca gráfica de TradingView, Podrá visualizar con precisión los puntos clave de su estrategia. Esto le permitirá optimizar su enfoque de manera intuitiva, directamente desde el gráfico, sin tener que salir del mismo."
            }
        ]
    },
    usps: {
        subheading: "Automatización de vanguardia para el trading",
        heading: "Bot de trading potente y fácil de usar",
        description: (
            <span>
                Revolucione su experiencia de trading con Gunbot, el bot de trading definitivo diseñado tanto para entusiastas de las criptomonedas como para expertos en automatización. Tanto si eres un trader experimentado como si acabas de empezar, Gunbot te permite ejecutar tus estrategias de trading sin esfuerzo, maximizando tus beneficios con cada movimiento.
            </span>
        ),
        imageAlt: "Cree fácilmente una estrategia de trading personalizada",
        features: [
            {
                title: "Control total, sin depender de la nube",
                description: (
                    <span>
                        A diferencia de otros bots de trading, Gunbot se ejecuta directamente en tu ordenador. No tiene que depender de servicios en la nube, garantizando la seguridad y privacidad de sus datos. Compatible con Windows, macOS, Linux y Raspberry Pi, Gunbot ofrece una flexibilidad inigualable.
                    </span>
                ),
            },
            {
                title: "Estrategias Plug and Play",
                description: (
                    <span>
                        Descubra el poder del plug and play con Gunbot. Estrategias <LinkA href={"https://www.gunbot.com/support/guides/trading-logic-and-optimization/selecting-spot-strategies/"} target="_blank" title="Cómo seleccionar una estrategia en Gunbot">rentables</LinkA> tales como stepgridscalp vienen preajustadas, lo que le permite poner en marcha su rendimiento de trading al instante. Sólo tiene que encender el bot y ver cómo aumentan sus beneficios.
                    </span>
                ),
            },
            {
                title: "Posibilidades ilimitadas para usuarios avanzados",
                description: (
                    <span>
                        ¿Eres un usuario avanzado que ansía la personalización? Gunbot te lo pone fácil. Disfruta de <LinkA href={"https://www.gunbot.com/support/faq/multiple-bot-instances/"} target="_blank" title="¿Por qué ejecutar varias instancias de Gunbot?">instancias de bot ilimitadas</LinkA>, Elabore sus estrategias personalizadas y automatice los cambios de configuración. Las posibilidades son infinitas: lleve su trading al siguiente nivel.
                    </span>
                ),
            },
            {
                title: "Actualizaciones gratuitas",
                description: "Gunbot ofrece actualizaciones de software gratuitas, incluso para licencias permanentes. Pague sólo una licencia y disfrute de mejoras continuas para mejorar sus estrategias de trading. También disponemos de suscripciones, si aún no está preparado para la licencia vitalicia.",
            }
        ],
        learnMoreLinkText: "Programe estrategias personalizadas en JS",
        learnMoreLinkURL: "/es/caracteristicas/estrategias-personalizadas/"
    },
    grid: {
        subheading: "Estrategias para spot y futuros, multi temporalidad y facilidad de uso.",
        heading: "Sistema de Grid Trading como nunca lo había visto antes.",
        description: (
            <span>
                Las estrategias grid de Gunbot redefinen la forma de hacer trading. Pero, ¿qué diferencia a Gunbot de las estrategias tradicionales? Todo radica en las funciones de trading inteligente:
                <br /><br />
                <strong>Gridbots con órdenes límite super fáciles: </strong>¿Desea un gridbot con órdenes limitadas, con reequilibrio y configuración personalizada de los pasos del Grid? Gunbot lo tiene cubierto.
                <strong>Análisis de tendencias integrado:</strong> El análisis de tendencias incorporado en Gunbot le mantiene alejado de las zonas desfavorables del mercado, asegurando que tome decisiones más inteligentes.<br /><br />
                <strong>Ajustes dinámicos de objetivos:</strong> Los objetivos pueden adaptarse automáticamente a la reciente volatilidad del mercado, optimizando sus resultados de trading.<br /><br />
                <strong>Trailing para mejores tasas de ejecución:</strong> Trailing para mejores tasas de ejecución: La función de trailing de Gunbot asegura que obtengas las mejores tasas de ejecución en tus compras o ventas. Incluso en estrategias Grid.<br /><br />
                Las estrategias están diseñadas para producir resultados de trading excepcionales. Observe las imágenes de los gráficos de esta página – Observe las imágenes de los gráficos de esta página: cada flecha verde representa una orden de compra, mientras que cada flecha naranja representa una orden de venta. Es una representación visual de su éxito.
            </span>
        ),
        imageAlt: "Grid con DCA: objetivos de ganancia en piloto automático.",
    },
    testimonialsData: [
        {
            customerName: "gt3209",
            quote:
                " Comencé a investigar y encontré en las RRSS a una persona que, en ese momento, me atendió muy amablemente. Al pasar de los días, meses y años, la relación proveedor-cliente ha crecido al igual que la amistad. Sin temor a equivocarme, es el mejor vendedor de la empresa y uno de los mejores, es un verdadero CRACK. Yo lo elegí como mi supermentor. Gracias, bro Uri @crazymop, por toda tu atención.",
            position: 'Miembro de la comunidad' // optional line, placed 'Community member' by default, when omitted.
        },
        {
            customerName: "Gabriel",
            quote:
                "Es el mejor software/Bot que he utilizado en mi vida, había probado muchos pero definitivamente Gunbot es el mejor. Entre más conozco los productos, más me enamoro de ellos, a veces, de ver las ganancias hasta el sueño se me quita de la emoción de ver cómo crece el capital. Uno de los puntos más importantes es que el crecimiento es diario, las actualizaciones igual; la preocupación, yo lo llamaría mejor ocupación, de que los softwares día a día funcionen mejor para tener los mejores resultados... ¡son los mejores! No me cansaré de dar las gracias a mi supermentor Uri y todo el equipo por todos los aportes. Por eso creo que Gunbot sigue siendo el mejor robot comercial. La comunidad importa, especialmente con las criptomonedas.",
            position: 'Miembro de la comunidad'
        },
        {
            customerName: "Joselete",
            quote:
                "Una pasada ver crecer tus ahorros día tras día mientras me dedico a otras labores. Una suerte haber contactado con Uri que me ha ayudado en todo momento a conseguir el objetivo. Gunbot funciona y toda la comunidad que hay detrás también!",
            position: 'Miembro de la comunidad'
        },
        {
            customerName: "Edu",
            quote:
                "Despues de probar muchos bots, plataformas milagrosas y promesas increibles encontre un Bot con una comunidad muy grande, actualizaciones frecuentes y con una atencion al cliente espectacular.. Gracias a Uri he podido tener un bot en automatico generando ganancias de manera estable y constante que es lo mas dificil de todo, he comprado mas de 12 licencias y todo gracias a lo que me ha ayudado y enseñado Uri, recomiendo encarecidamente este bot si de verdad quieres ganar en este mundo de las criptos pero para mi la clave es que @crazymop te ayude!",
            position: 'Miembro de la comunidad'
        },
        {
            customerName: "Xuls",
            quote:
                "Descubrí gunbot por casualidad y es el mejor servicio de trading que he podido encontrar. Una atención inmejorable y un software casi perfecto en continua mejora!!! gran trabajo y a seguir así!!!",
            position: 'Miembro de la comunidad'
        },
        {
            customerName: "BitcoinSobreLasNubes",
            quote:
                "Me gustaría compartir mi experiencia con Uri, el equipo de soporte de Gunbot. Desde que empecé a usar este software,  Uri ha estado siempre disponible y dispuesto a ayudar en cualquier momento. Su amabilidad y paciencia han sido reconfortantes, brindándome asistencia cada vez que lo he necesitado. A lo largo del tiempo, Uri no solo me ha ayudado directamente, sino que también me ha enseñado a solucionar problemas con el bot por mí mismo. Esta capacidad de enseñanza ha sido invaluable para mí, ya que me ha permitido entender mejor el funcionamiento del bot y sentirme más seguro en su uso. En cuanto al bot en sí, debo decir que estoy gratamente sorprendido por su efectividad. Gunbot ha superado mis expectativas y ha demostrado ser una herramienta poderosa para mis operaciones en criptomonedas. La colaboración con Uri y el uso de Gunbot han sido una experiencia enriquecedora para mí. Recomiendo sinceramente tanto el bot como el excepcional servicio de soporte proporcionado por Uri. Estoy muy agradecido por su ayuda y por hacer posible esta experiencia positiva. Con gratitud.",
            position: 'Miembro de la comunidad'
        },         
    ],
    why: {
        cards: [
            {
                title: "Escudo de privacidad: sus operaciones, su negocio",
                description: "Gunbot es el bot de trading más respetuoso con la privacidad que hay en el mercado. A diferencia de muchos otros bots de trading, nunca recolecta ningún dato sobre las operaciones que usted realice. Sus transacciones son totalmente confidenciales, compartidas sólo entre usted y su exchange. Con Gunbot, su privacidad es siempre lo primero."
            },
            {
                title: "Potenciando el trading manual y con bots",
                description: <>Si está buscando <LinkA href={"https://www.gunbot.com/support/faq/difference-automated-manual-trading-gunbot/"} target="_blank" title="Cómo usar Gunbot en combinación con el trading manual">Combinar</LinkA> El trading con bots y el trading manual en la misma cuenta de exchange, Gunbot es tu compañero ideal.





                    <br /><br />
                    Puedes añadir manualmente sin esfuerzo a las posiciones abiertas por Gunbot, proporcionándole la flexibilidad que necesitas para tu estrategia de trading. Descubra {<Link to="/use-cases/" title="Gunbot use cases">diferentes formas</Link>} de usar Gunbot.</>
            },
            {
                title: "Respondemos a sus preguntas en todo momento",
                description: <>
                    Gunbot no sólo ofrece potentes funciones, sino también {<LinkA href="/support/" target="_blank" title="Gunbot apoya a la comunidad">Un gran soporte</LinkA>}. Tanto si prefieres cursos, chat en directo, tickets por correo electrónico o más, Gunbot proporciona asistencia humana para atender todas tus consultas y asegurarse de que estás aprovechando al máximo sus amplias capacidades.</>
            }
        ],
        heading: "Por qué muchos traders eligen Gunbot",
        subheading: "",
        description: `Imagine operar sin limitaciones - Gunbot le da el poder de capturar oportunidades de beneficio en cada minuto, cada hora. Con Gunbot, sus iniciativas en criptomonedas nunca descansan, garantizando que siempre esté a la vanguardia en el mercado dinámico.`
    },
    charts: [
        { id: 1, title: 'Utiliza estrategias únicas de un bot de trading', description: 'Automatice las operaciones con una serie de estrategias de trading predefinidas. O bien cree su propia estrategia para un enfoque verdaderamente único.' },
        { id: 2, title: 'Use  múltiples marcos temporales con facilidad', description: 'Opere con estrategias que se adaptan a las tendencias de los marcos temporales superiores, tomando decisiones más inteligentes en todos los marcos temporales.' },
      ]
};


export default translations;
